import React, { useState, useEffect } from 'react'
import {
    Container,
    Grid,
    createStyles,
    withStyles,
    Typography,
} from '@material-ui/core'

import { Link } from 'gatsby'

import SVG, { Props as SVGProps } from 'react-inlinesvg'

import ScrollAnimation from 'react-animate-on-scroll'
import Head from 'react-helmet'

import Layout from '../../components/Layout'
import Net_Worth from '../../Home/Features/Savings_Plan.png'
import SignUp from '../../components/Layout/Nav/SignUp'

import networth from '../../images/savings-icon.png'
import sync from './icons/edit.svg'
import time from './icons/zoomIn.svg'
import manualTrack from './icons/zoomOut.svg'
import comprehensive from './icons/resources.svg'

// import Net_Worth from './Net_Worth.png'
// import Savings_Plan from './Savings_Plan.png'

const styles = (theme) =>
    createStyles({
        contentContainer: {
            display: 'flex',
            justifyContent: 'center',
        },
        content: {
            width: '330px',
            [theme.breakpoints.up('md')]: {
                width: '340px',
            },
            [theme.breakpoints.up('lg')]: {
                width: '320px',
            },
        },
        WrapperSection: {
            [theme.breakpoints.up('xs')]: {
                marginTop: '50px',
            },
            [theme.breakpoints.up('lg')]: {
                marginTop: '170px',
            },
        },
        container: {
            textAlign: "center",
            [theme.breakpoints.up('xs')]: {
                maxWidth: '77.625rem',
            },
            [theme.breakpoints.up('md')]: {
                maxWidth: '58rem',
                padding: 0,
            },
            [theme.breakpoints.up('lg')]: {
                maxWidth: '77.625rem',
            },
        },
        row: {
            margin: "2rem 0",
            '& + &': {
                [theme.breakpoints.up('md')]: {
                    // marginTop: '10rem',
                },
                [theme.breakpoints.up('lg')]: {
                    // marginTop: '9.75rem',
                },
            },
        },
        section: {
            marginBottom: '2.3125rem',
            textAlign: 'center',
            padding: "0 1.875rem",
            listStyle: 'none',
            // width: '100%',
            [theme.breakpoints.up('xs')]: {
                padding: "0px",
                margin: "1.5em",
            },
            [theme.breakpoints.up('md')]: {
                width: '18rem',
                marginRight: '1.5rem',
            },
            [theme.breakpoints.up('lg')]: {
                width: '22rem',
                marginRight: '4.4375rem',
            },
        },
        icon: {
            width: "40px",
        },
        cardTitle: {
            color: '#F0EDEF',
            [theme.breakpoints.up('xs')]: {
                margin: "1em",
            },
            [theme.breakpoints.up('lg')]: {
                margin: "1.5em"
            },
        },





        title: {
            fontWeight: 800,
            lineHeight: '1.32',
            color: '#F0EDEF',

            fontSize: '30px',
            width: '210px',
            margin: '0 auto 1.5rem',

            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },

            [theme.breakpoints.up('md')]: {
                margin: '0 0 1.5rem',
                fontSize: '48px',
                width: '340px',
            },
        },
        description: {
            marginBottom: "1.5rem",
            fontSize: '16px',

            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },

            [theme.breakpoints.up('md')]: {
                fontSize: '26px',
            },
        },
        heroImg: {
            width: "100%",
            marginBottom: "1.5em",
            [theme.breakpoints.up('xs')]: {
                order: 1,
            },
            [theme.breakpoints.up('md')]: {
                order: 2,
                width: "90%",
                marginBottom: "1.5em",
            },
            [theme.breakpoints.up('lg')]: {
                order: 2,
                width: "100%",
                marginBottom: "1.5em",
            },
        },
        mainIcon: {
            [theme.breakpoints.up('xs')]: {
                display: 'none'
            },
            [theme.breakpoints.up('md')]: {
                display: 'block'
            },
            [theme.breakpoints.up('lg')]: {
                display: 'block'
            },
        },
        scrollContainer: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up('xs')]: {
                marginBottom: '80px',
            },
            [theme.breakpoints.up('md')]: {
                display: 'block',
                marginBottom: '0px',
            },
        },
        pageHeader: {
            textAlign: "left",
            position: "relative",

            [theme.breakpoints.up('xs')]: {
                order: 2,
                textAlign: "center",
                width: "100%",
                '& img': {
                    position: 'relative',
                    // left:"0px",
                    width: "60px"
                }
            },
            [theme.breakpoints.up('sm')]: {
                paddingLeft: "5rem",
                marginLeft: "6rem",
                textAlign: "left",
                width: "50%",
                '& img': {
                    position: 'absolute',
                    left: "0px",
                    width: "60px"
                },
            },
            [theme.breakpoints.up('md')]: {
                order: 1,
                paddingLeft: "5rem",
                marginLeft: "6rem",
                textAlign: "left",
                width: "45%",
                '& img': {
                    position: 'absolute',
                    left: "0px",
                    width: "60px"
                },
            },
            [theme.breakpoints.up('lg')]: {
                paddingLeft: "5rem",
                marginLeft: "7rem",
                textAlign: "left",
                '& img': {
                    position: 'absolute',
                    left: "0px",
                    width: "60px"
                },
            },
        },
        buttonWrapper: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.up('xs')]: {
                justifyContent: 'center',
            },
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start',
            },
        },
        buttonSignUp: {
            [theme.breakpoints.up('xs')]: {
                padding: '2px',
                width: '100px',
                height: "30px",
                fontSize: "12px"
            },
            [theme.breakpoints.up('md')]: {
                padding: '0.425rem',
                width: '200px',
                height: "35px",
                fontSize: "14px"
            },
            fontFamily: 'Montserrat',
            backgroundColor: '#FF0080',
            color: '#fff',
            borderRadius: 100,
            border: 0,
            padding: '0.625rem',
            cursor: 'pointer',
            fontWeight: '700',
            fontSize: 14,
            marginRight: 15,
        },
        buttonLearn: {
            [theme.breakpoints.up('xs')]: {
                padding: '2px',
                width: '100px',
                height: "30px",
                fontSize: "12px"
            },
            [theme.breakpoints.up('md')]: {
                width: '200px',
                height: "35px",
                fontSize: "14px"
            },
            fontFamily: 'Montserrat',
            backgroundColor: '#000000',
            color: '#fff',
            borderRadius: 100,
            border: '1px solid #F0EDEF',
            padding: '0.625rem',
            cursor: 'pointer',
            fontWeight: '700',
            fontSize: 14,
        }
    })

const Features = ({ classes }) => {
    const [active, setActive] = useState(false)
    const FeaturesRow = (props) => (
        <Grid
            {...props}
            container
            justify="center"
            className={classes.row}
        />
    )

    const [isPhone, setIsPhone] = useState(false)

    useEffect(() => {
        setIsPhone(window.innerWidth <= 599)
        window.addEventListener("resize", handleResize);
    }, [])

    const handleResize = (e) => {
        setIsPhone(window.innerWidth <= 599)
    }

    return (
        <Layout>
            <Head>
                <title>Savings Plan</title>
            </Head>
            <section className={classes.WrapperSection}>
                <Container className={classes.container}>
                    <ScrollAnimation animateIn="fadeIn" animateOnce>
                        <div className={classes.scrollContainer}>
                            <div className={classes.pageHeader}>
                                <img src={networth} className={classes.mainIcon} />

                                <Typography className={classes.title} variant="h3">
                                    Your Savings Plan
                                </Typography>
                                <Typography className={classes.description}>
                                    Create a monthly savings plan in minutes to ensure you are saving for your future.
                                </Typography>
                                <div className={classes.buttonWrapper}>
                                    <Link to={isPhone ? 'https://avocadofinance-alternate.app.link' : '/get-app'} className={classes.link}>
                                        <button className={classes.buttonSignUp}>Sign Up</button>
                                    </Link>
                                </div>
                            </div>
                            <img src={Net_Worth} alt="networth" className={classes.heroImg} />
                        </div>
                        <FeaturesRow>
                            <Grid item xs={12} sm={5} md={4} className={classes.section}>
                                <div className={classes.header}>
                                    <img src={sync} alt="icon" className={classes.icon} />
                                    <Typography variant="h4" className={classes.cardTitle} >
                                        Create a savings plan
                                    </Typography>

                                </div>
                                <div className={classes.contentContainer}>
                                    <Typography align="center" className={classes.content}>
                                        Answer basic questions and create a savings plan based on your income and spend.
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={5} md={4} className={classes.section}>
                                <div className={classes.header}>
                                    <img src={time} alt="icon" className={classes.icon} />
                                    <Typography variant="h4" className={classes.cardTitle}>
                                        Income calculation
                                    </Typography>

                                </div>
                                <div className={classes.contentContainer}>
                                    <Typography align="center" className={classes.content}>
                                        Your after-tax income is calculated for you based on your filing status, location, and tax bracket.
                                    </Typography>
                                </div>
                            </Grid>
                            {/* </div> */}
                        </FeaturesRow>
                        <FeaturesRow>
                            {/* <div className={classes.section}> */}
                            <Grid item xs={12} sm={5} md={4} className={classes.section}>
                                <div className={classes.header}>
                                    <img src={manualTrack} alt="icon" className={classes.icon} />
                                    <Typography variant="h4" className={classes.cardTitle}>
                                        Detailed expenses
                                    </Typography>

                                </div>
                                <div className={classes.contentContainer}>
                                    <Typography align="center" className={classes.content}>
                                        We guide you so you can easily create a list of your regular monthly expenses.
                                    </Typography>
                                </div>
                            </Grid>
                            {/* </div> */}
                            {/* <div className={classes.section}> */}
                            <Grid item xs={12} sm={5} md={4} className={classes.section}>
                                <div className={classes.header}>
                                    <img src={comprehensive} alt="icon" className={classes.icon} />
                                    <Typography variant="h4" className={classes.cardTitle} >
                                        Test different scenarios
                                    </Typography>

                                </div>
                                <div className={classes.contentContainer}>
                                    <Typography align="center" className={classes.content}>
                                        Test out different scenarios and see how it impacts your financial future.
                                    </Typography>
                                </div>
                            </Grid>
                            {/* </div> */}
                        </FeaturesRow>
                    </ScrollAnimation>
                </Container>
            </section>
        </Layout>
    )
}

export default withStyles(styles)(Features)
